// import type8GridDebug from "@jeremyfagis/type8-grid-debug";

import anime, { easings } from 'animejs';
import SplitType from 'split-type'
import LocomotiveScroll from 'locomotive-scroll';

import WindowUtils from './utils/WindowUtils';
import Utils from "./utils/Utils";

// new type8GridDebug(14,0);

const WU = new WindowUtils();

if (WU.isTouch()) {
    document.body.classList.add('isTouch');
}

let scroll;

/* ------------------------------------ */
/* ------ Locomotive scroll init ------ */
/* ------------------------------------ */
const createScroll = () => {
    scroll = new LocomotiveScroll();
};

const scrollTo = (elt) => {
    scroll.scrollTo(elt, { duration: 1, easing: (x) => x === 1 ? 1 : 1 - Math.pow(2, -10 * x) });
};

window.addEventListener('titleSplit', (e) => {
    const { target, way, from } = e.detail;
    const words = [...target.querySelectorAll(".word")];
    if (way == 'enter') {
        anime({
            targets: words,
            translateY: ['100%', '0%'],
            rotate: [10, 0],
            // scale: [0.8, 1],
            filter: ["blur(10px)", "blur(0px)"],
            opacity: [0, 1],
            duration: 600,
            easing: 'easeOutExpo',
            delay: anime.stagger(60),
        });
    } else {
        anime({
            targets: words,
            translateY: ['0%', '100%'],
            rotate: [0, 10],
            // scale: [1, 0.8],
            filter: ["blur(0px)", "blur(10px)"],
            opacity: [1, 0],
            duration: 600,
            easing: 'easeOutExpo',
            // delay: anime.stagger(30, { from: 'last' }),
        });
    }
});

window.addEventListener('titleSplit2', (e) => {
    const { target, way, from } = e.detail;
    const words = [...target.querySelectorAll(".word")];
    
    if (way == 'enter') {
        anime({
            targets: words,
            translateX: ['0.75em', '0em'],
            filter: ["blur(10px)", "blur(0px)"],
            opacity: [0, 1],
            duration: 600,
            easing: 'easeOutExpo',
            delay: anime.stagger(80),
        });
    } else {
        anime({
            targets: words,
            translateX: ['0em', '0.75em'],
            filter: ["blur(0px)", "blur(10px)"],
            opacity: [1, 0],
            duration: 600,
            easing: 'easeOutExpo',
            delay: anime.stagger(10, { from: 'last' }),
        });
    }
});

window.addEventListener('titleSplit3', (e) => {
    const { target, way, from } = e.detail;
    const lines = [...target.querySelectorAll(".line")];
    const linesReverse = [...target.querySelectorAll(".line")].reverse();

    if (way == 'enter') {
        lines.forEach((line, index) => {
            anime({
                targets: [...line.querySelectorAll(".word")],
                translateY: ['100%', '0'],
                duration: 600,
                easing: 'easeOutExpo',
                delay: index * 150,
            });
        })
    } else {
        linesReverse.forEach((line, index) => {
            anime({
                targets: [...line.querySelectorAll(".word")],
                translateY: ['0', '100%'],
                duration: 600,
                easing: 'easeOutExpo',
                delay: index * 150,
            });
        })
    }
});

window.addEventListener('titleSplit4', (e) => {
    const { target, way, from } = e.detail;
    const lines = [...target.querySelectorAll(".line")];
    const linesReverse = [...target.querySelectorAll(".line")].reverse();

    if (way == 'enter') {
        lines.forEach((line, index) => {
            anime({
                targets: [...line.querySelectorAll(".word")],
                translateY: ['120%', '0'],
                filter: ["blur(20px)", "blur(0px)"],
                opacity: [0, 1],
                duration: 600,
                easing: 'easeOutExpo',
                delay: index * 150,
            });
        })
    } else {
        linesReverse.forEach((line, index) => {
            anime({
                targets: [...line.querySelectorAll(".word")],
                translateY: ['0%', '120%'],
                filter: ["blur(0px)", "blur(20px)"],
                opacity: [1, 0],
                duration: 600,
                easing: 'easeOutExpo',
                delay: index * 150,
            });
        })
    }
});



// JS CUSTOM HERE
const page = () => {

    // NAV
    document.querySelectorAll('nav a').forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            scrollTo(link.getAttribute('href'));
        });
    });


    if (document.querySelectorAll('[data-split]')) {
        document.querySelectorAll('[data-split]').forEach((splitElt) => {
            let splitType = (splitElt.getAttribute('data-split')) ? splitElt.getAttribute('data-split') : 'chars';
            new SplitType(splitElt, { types: "lines, " + splitType });
        });
    }


    // Text animations
    if (document.querySelectorAll('.textAnim')) {
        document.querySelectorAll('.textAnim').forEach((elt) => {
            elt.addEventListener('mouseenter', (e) => {
                let icon = elt.querySelector('.textAnim_icon');
                let chars = elt.querySelectorAll('.char');

                anime({
                    targets: [icon, chars],
                    translateX: '1.2em',
                    delay: anime.stagger(9, { from: 'last' }),
                    duration: 400,
                    easing: 'easeOutExpo'
                });
            });

            elt.addEventListener('mouseleave', (e) => {
                let icon = elt.querySelector('.textAnim_icon');
                let chars = elt.querySelectorAll('.char');

                anime({
                    targets: [icon, chars],
                    translateX: '0',
                    delay: anime.stagger(9, { from: 'first' }),
                    duration: 400,
                    easing: 'easeOutExpo'
                });
            });
        });
    }


    if (document.querySelectorAll('.button')) {
        document.querySelectorAll('.button').forEach((elt) => {
            elt.addEventListener('mouseenter', (e) => {
                let charsDefault = elt.querySelector('.button_chars--default').querySelectorAll('.char');
                let charsHover = elt.querySelector('.button_chars--hover').querySelectorAll('.char');

                anime({
                    targets: charsDefault,
                    translateY: ['0%', '-110%'],
                    delay: anime.stagger(12, { from: 'first' }),
                    duration: 600,
                    easing: 'easeOutExpo'
                });

                anime({
                    targets: charsHover,
                    translateY: ['110%', '0%'],
                    delay: anime.stagger(12, { from: 'first' }),
                    duration: 600,
                    easing: 'easeOutExpo'
                });
            });

            elt.addEventListener('mouseleave', (e) => {
                let charsDefault = elt.querySelector('.button_chars--default').querySelectorAll('.char');
                let charsHover = elt.querySelector('.button_chars--hover').querySelectorAll('.char');

                anime({
                    targets: charsDefault,
                    translateY: ['-110%', '0%'],
                    delay: anime.stagger(12, { from: 'last' }),
                    duration: 600,
                    easing: 'easeOutExpo'
                });

                anime({
                    targets: charsHover,
                    translateY: ['0%', '110%'],
                    delay: anime.stagger(12, { from: 'last' }),
                    duration: 600,
                    easing: 'easeOutExpo'
                });
            });
        });
    }


    if (document.querySelectorAll('.button2')) {
        document.querySelectorAll('.button2').forEach((elt) => {
            elt.addEventListener('mouseenter', (e) => {
                let chars = elt.querySelectorAll('.char');

                anime({
                    targets: chars,
                    keyframes: [
                        {opacity: 0},
                        {opacity: 1},
                    ],
                    delay: anime.stagger(15, { from: 'first' }),
                    duration: 500,
                    easing: 'linear'
                });
            });
        });
    }

    if (document.querySelectorAll('.button3')) {
        document.querySelectorAll('.button3').forEach((elt) => {
            elt.addEventListener('mouseenter', (e) => {
                const chars = [...elt.querySelectorAll(".char")];
                
                anime({
                    targets: Utils.shuffle(chars),
                    opacity: [0, 1],
                    duration: 300,
                    easing: 'linear',
                    delay: anime.stagger(20),
                });
            });
        });
    }

    if (document.querySelectorAll('.button4')) {
        document.querySelectorAll('.button4').forEach((elt) => {
            elt.addEventListener('mouseenter', (e) => {
                const chars = [...elt.querySelectorAll(".char")];

                anime({
                    targets: Utils.shuffle(chars),
                    keyframes: [
                        {opacity: 0},
                        {opacity: 1},
                        {opacity: 0},
                        {opacity: 1},
                    ],
                    delay: anime.stagger(20, { from: 'first' }),
                    duration: 600,
                    // easing: 'steps(3)'
                });
            });
        });
    }


    if (document.querySelectorAll('.imageLayers')) {
        const maxMoveX = 10;
        const maxMoveY = 10;

        document.querySelectorAll('.imageLayers').forEach((elt) => {
            elt.addEventListener('mousemove', (e) => {
                let rect = elt.getBoundingClientRect();
                const posX = (((e.clientX - rect.left) / rect.width) - 0.5);
                const posY = (((e.clientY - rect.top) / rect.height) - 0.5);

                elt.style.setProperty("--xPosition", (posX * maxMoveX * -1) + "%");
                elt.style.setProperty("--yPosition", (posY * maxMoveY * -1) + "%");
            });

            elt.addEventListener('mouseleave', (e) => {
                elt.style.setProperty("--xPosition", 0);
                elt.style.setProperty("--yPosition", 0);
            });
        });
    }

    if (document.querySelector('.launchIntro')) {
        document.querySelectorAll('.launchIntro').forEach((elt) => {
            let parent = elt.closest('.intro');
            let bgs = parent.querySelectorAll('.intro_background_items img');
            elt.addEventListener('click', () => {
                anime({
                    targets: bgs,
                    scale: [1.5, 1.25],
                    opacity: [0, 1],
                    easing: 'easeOutExpo',
                    duration: 800,
                    delay: anime.stagger(150),
                    complete: () => {
                        parent.classList.add('zoomed');
                    }
                });
            });
        });
    }
    
    if (document.querySelector('.resetIntro')) {
        document.querySelectorAll('.resetIntro').forEach((elt) => {
            let parent = elt.closest('.intro');
            let bgs = parent.querySelectorAll('.intro_background_items img');
            elt.addEventListener('click', () => {
                parent.classList.remove('zoomed');
                anime.set(bgs, {
                    opacity: 0,
                });
            });
        });
    }

};

const initPage = () => {
    createScroll();
    page();
};

// const hideLoading = () => {
//     setTimeout(() => {
//         document.querySelector('.loadingScreen').classList.remove('visible');
//     }, 500);
// };

(function () {
    Utils.preloadHTMLImages(document.body.innerHTML).then(() => {
        initPage();
        // hideLoading();
    });
})();