import Utils from "./Utils.js";

class WindowUtils {
    
    constructor() {
        this.w = {
            h: window.innerHeight,
            w: window.innerWidth,
            st: window.scrollY,
            maxScroll: 0,
            scrollPct: 0
        };

        this.BREAKPOINTS = {
            XS: { NAME: "xs", MIN: 0,    MAX: 767  },
            SM: { NAME: "sm", MIN: 768,  MAX: 1023  },
            MD: { NAME: "md", MIN: 1024,  MAX: 1299 },
            LG: { NAME: "lg", MIN: 1300, MAX: 1599 },
            XL: { NAME: "xl", MIN: 1600, MAX: null }
        };

        this.updateWindowH();
        this.updateWindowW();
        this.updateWindowScroll();
        this.updateMaxScroll();
        this.updateScrollPct();

        window.addEventListener('resize', Utils.debounce(() => { this.onResize() }), { passive: true });
        window.addEventListener('scroll', Utils.debounce(() => { this.onScroll() }), { passive: true });
    }

    isTouch () {
        return 'ontouchstart' in document.documentElement;
    }
    
    isXS () {
        return window.innerWidth <= this.BREAKPOINTS.XS.MAX;
    }

    isSM () {
        return window.innerWidth >= this.BREAKPOINTS.SM.MIN && window.innerWidth <= this.BREAKPOINTS.SM.MAX;
    }
    
    isMD () {
        return window.innerWidth >= this.BREAKPOINTS.MD.MIN && window.innerWidth <= this.BREAKPOINTS.MD.MAX;
    }

    isLG () {
        return window.innerWidth >= this.BREAKPOINTS.LG.MIN && window.innerWidth <= this.BREAKPOINTS.LG.MAX;
    }

    isXL () {
        return window.innerWidth >= this.BREAKPOINTS.XL.MIN;
    }
    
    isTablet () {
        return isSM() || isMD();
    }

    isDesktop () {
        return isLG() || isXL();
    }

    isSafari () {    
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    deviceType () {
        let ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "mobile";
        }
        return "desktop";
    }

    updateWindowH () {
        this.w.h = window.innerHeight;
    }

    updateWindowW () {
        this.w.w = window.innerWidth;
    }

    updateWindowScroll () {
        this.w.st = window.scrollY;
        this.updateScrollPct();
    }

    updateMaxScroll () {
        this.w.maxScroll = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - this.w.h;
    }

    updateScrollPct () {
        this.w.scrollPct = this.w.st / this.w.maxScroll;
    }

    updateVh () {
        var vh = this.w.h * 0.01 + "px";
        document.documentElement.style.setProperty('--vh', vh);
    }

    onResize () {
        this.updateWindowH();
        this.updateWindowW();
        this.updateWindowScroll();
        this.updateMaxScroll();
        this.updateScrollPct();
        // this.updateVh();
    }
    
    onScroll () {
        this.updateWindowScroll();
    }

    scrollToTop () {
        window.scrollTo(0,0);
        this.updateWindowScroll();
    }
    
}

export default WindowUtils;